import { inject, onMounted, onUnmounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { store } from "../store";
import Segments from "./Segments";

export default function AlgoliaSearch() { 
  const axios = inject("axiosAlgolia");
  const axios2 = inject("axios");
  const route = useRoute();

  const products = reactive({
    loading: false, 
    data: {
      data: []
    },
    error: "",
  });

  let product_ids = ref('');

  const getProducts = () => {
    products.loading = true;
    axios.api
      .get("/products_index", {
        params: {
            query: route.query.q,
            hitsPerPage: 250
        },
      })
      .then((resp) => {
        if(resp.status == 200){
          let data = resp.data.hits.filter(
            (i) => (i.status === 'active' && i.active === 1) && (store.getters.cart.price_list_id == '33' || i.tags != 'GGV ONLY')
          );

          product_ids.value = data
            .map(item => item.id)
            .join(',');

          let brands = [];
          let categories = [];
          let b = data.filter((v, i, s) => i === s.findIndex(t => t.brand === v.brand));
          b.forEach(e => 
            brands.push({
              id: e.brand_id,
              name: e.brand,
              slug: e.brand_slug
          }));
          let c = data.filter((v, i, s) => i === s.findIndex(t => t.product_type === v.product_type));
          c.forEach(e => 
            categories.push({
              id: e.category_id,
              name: e.categories[0],
              slug: e.categories_slug[0]
          }));
          store.dispatch("updateOptions", categories);
          store.dispatch("updateBrandFilter", brands);
          
          // get products from DB
          getData("loading");

        } else {
          brands.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  let page = 1;
  let sort = "latest";
  let stock = false;
  async function getData(load) {
    products[load] = true;
    const brand = store.getters.getSelected
      .filter((r) => r.type == "brand")
      .map((r) => r.id);
    const category = store.getters.getSelected
      .filter((r) => r.type == "category")
      .map((r) => r.id);
    await axios2.authApi.get('search-products', {
      params: {
          page,
          sort,
          stock,
          brand,
          category,
          ids: product_ids.value,
      }
    }).then(res => {
      products[load] = false;
      let data = res.data.data;
      
      products.data.current_page = page;
      products.data.last_page = data.last_page;
      products.data.total = data.total;
      products.data.data.push(...data.data);
    })
    .finally(() => (products[load] = false))
  }

  const reset = () => {
    products.data = {
      data: []
    };
    page = 1;
  };
  async function onSortChanged(v) {
    if(v){
      reset();
      sort = v;
      await getData("loading");
    } else {
      getProducts();
    }
  }

  async function onStockChanged(v) {
    reset();
    stock = v;
    store.dispatch("updateOptions", []);
    store.dispatch("removeOption", "clear");
  }

  async function onFilterChanged() {
    reset();
    await getData("loading");
  }

  async function handleScroll() {
    const itemsContainer = document.querySelector(".items-listing-container");
    if (!itemsContainer) {
      return;
    }
    const canLoadMore = page < products.data.last_page;
    const elemScrollHeight = itemsContainer.scrollHeight;
    const elemScrollLoadHeight = elemScrollHeight - elemScrollHeight * 0.5;
    const shouldLoadMore =
      window.pageYOffset + 200 > elemScrollLoadHeight ||
      elemScrollHeight < window.innerHeight ||
      window.pageYOffset + elemScrollLoadHeight < window.innerHeight;

    if (canLoadMore && shouldLoadMore && !products.isLoadingScrollData) {
      page++;
      products.isLoadingScrollData = true;
      await getData("onScrollLoading");
      // products.data.data?.push(...res.data);
      products.isLoadingScrollData = false;
    }
  }

  // this will register the event when the component is mounted on the DOM
  onMounted(() => window.addEventListener("scroll", handleScroll));

  // We then unregister the listener when the component is removed from the DOM
  onUnmounted(() => window.removeEventListener("scroll", handleScroll));

  return {
    products,
    getProducts,
    onSortChanged,
    onFilterChanged,
    onStockChanged
  };
}
