<template>
  <div class="brand w-full pb-10">
    <div class="collection-banner-container" style="background-color: #7281BA">
      <Banner 
        :title="`Search result for “${qry}”`" 
        :toggle="true"
        @enabled="onStockChanged"
        logo="/images/search.svg" 
      >
        <template #header>
          <ol role="list" class="flex items-center space-x-2">
            <li>
              <div class="flex">
                <a
                  href="#"
                  class="text-sm font-light text-white text-opacity-60"
                  >Home</a
                >
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <span class="text-sm text-white text-opacity-60 font-normal"
                  >/</span
                >
                <a
                  href="#"
                  class="ml-2 text-sm font-light text-white text-opacity-60"
                  >Search</a
                >
              </div>
            </li>
          </ol>
        </template>
        <template #footer>
          <div class="-mb-8">
            <Sorting @onSort="onSortChanged" />
            <SortFilterMobile @onSort="onSortChanged" />
          </div>
          <!-- Footer Slot -->
        </template>
      </Banner>
    </div>
    <div class="py- 5 md:py-10 max-container">
      <!-- Listing -->
      <div class="flex items-center space-x-4 pb-5">
        <FilterBadge />
      </div>
      <ListLoaderWrapper :isLoading="products.loading" />

      <ItemsList
        :items="products"
        :filter="filters"
        :loading="products.loading"
      />
    </div>
    <ListLoaderWrapper :isLoading="products.onScrollLoading" />
  </div>
</template>

<script>
import Banner from "../common/Banner.vue";
import AlgoliaSearch from "@/modules/AlgoliaSearch";
import { useRoute } from "vue-router";
import { mapGetters, useStore } from "vuex";
import ListLoaderWrapper from "@/components/loaders/ListLoaderWrapper.vue";
import Segments from "@/modules/Segments";
import Categories from "@/modules/Categories";
import ItemsList from "@/views/pages/common/ItemsList.vue";
import FilterBadge from "@/components/FilterBadge.vue";
import SortFilterMobile from "@/components/SortFilterMobile.vue";

export default {
  name: "Home",
  components: {
    Banner,
    ListLoaderWrapper,
    ItemsList,
    FilterBadge,
    SortFilterMobile
  },
  unmounted() {
    this.$store.dispatch("productCountAction", 0);
  },
  watch: {
    filters: {
      handler: "filterCategory",
      deep: true,
    },
  },
  computed: {
    ...mapGetters({ filters: "getSelected" }),
  },
  methods: {
    filterCategory() {
      this.onFilterChanged();
    },
  },
  setup() {
    const route = useRoute();
    const qry = route.query.q;
    const store = useStore();

    const { products, onFilterChanged, onSortChanged, onStockChanged } = AlgoliaSearch();
    onSortChanged();

    const { isLoggedIn } = Categories();

    const { ProductClicked, ProductClickedNonLoggedIn } = Segments();

    const clicked = (item, pos) => {
      let data = {
        id: qry,
        type: 'search',
        price: Math.max(...item.variants_name.map(r => r.price)).toFixed(2),
        stock: item.variants_name.map(r => r.stockLevelAbsolute).reduce((a, b) => a + b, 0),
        status: item.variants_name.find(r => r.stockLevelAbsolute) ? true : false

      };

      if(isLoggedIn){
        
        // set list
        store.dispatch("setList", {
          list_id: data.id,
          list_type: data.type,
          position: pos+1,
        });

        ProductClicked(route, item, pos+1, data);
      } else {
        ProductClickedNonLoggedIn(route, item, pos+1, data);
      }

    };

    return {
      qry,
      products,
      onFilterChanged,
      onSortChanged,
      onStockChanged,
      clicked,
    };
  },
};
</script>

<style lang="scss" scoped>
.collection-banner-container {
  background-image: url("/images/lines-pattern.png");
}

.search-container {
  @apply grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-7;

  .items-list-card-inner {
    @apply py-3 px-4;
  }
}
</style>
